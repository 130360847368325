import useAgentData from '~/client/hooks/useAgentData';
import PageSection from '~/client/shared/PageSection';
import type PageSectionProps from '~/client/shared/PageSection/PageSectionProps';
import type { CasinoGameConnectionSorter } from '~/schema/public';
import mapSortByToRtpField from '~/shared/utils/carousel/mapSortByToRtpField';
import isListEmpty from '~/shared/utils/isListEmpty';
import useGameCarousel from './useGameCarousel';
import CustomCarousel from '../../Carousel/CustomCarousel';
import GameCard from '../GameCard';
import SourceGameSliderType from '@/client/helpers/trackEvent/umamiEvents/types/SourceGameSliderType';

type Props = {
  type?: 'game-carousel' | 'regular';
  menuItem?: string;
  theme?: string;
  big?: boolean;
  light?: boolean;
  order?: -1 | 1;
  sortBy?: keyof CasinoGameConnectionSorter;
  rtpField?: RtpField;
  placeholder?: JSX.Element;
  currentPlayingId?: string;
  preloadImgs?: boolean;
  sourcePage?: string;
  handle?: string;
  sliderName: SourceGameSliderType;
} & PageSectionProps;

const GameCarousel: FC<Props> = ({
  big,
  light,
  menuItem,
  theme,
  order,
  sortBy,
  placeholder,
  title,
  titleId,
  href,
  subtitleId,
  rtpField = mapSortByToRtpField(sortBy),
  currentPlayingId,
  type = 'game-carousel',
  preloadImgs,
  controlsOnTopRight,
  gameCarouselSorterIsActive,
  onChange,
  selectedSortValue,
  sourcePage,
  sliderName,
  handle,
}) => {
  const { games, loading } = useGameCarousel({
    menuItem,
    theme,
    order,
    sortBy,
    currentPlayingId,
    handle,
  });
  const { isMobile } = useAgentData();

  if (placeholder && loading) {
    return placeholder;
  }

  if (isListEmpty(games)) {
    return null;
  }

  const items = games?.map(({ node }, index) => {
    if (!node) {
      return null;
    }
    const preloadIndex = isMobile ? 2 : 6;
    return (
      <GameCard
        key={node.id}
        game={node}
        big={big}
        rtpField={rtpField}
        light={light}
        preloadImg={preloadImgs && index <= preloadIndex}
        isMinMaxBetVisible={controlsOnTopRight}
        isCarousel
        sourcePage={sourcePage}
        sliderName={sliderName}
        sliderTileIndex={index + 1}
      />
    );
  });

  return (
    <PageSection
      title={title}
      titleId={titleId}
      href={href}
      subtitleId={subtitleId}
      controlsOnTopRight={controlsOnTopRight}
      gameCount={items?.length}
      gameCarouselSorterIsActive={gameCarouselSorterIsActive}
      onChange={onChange}
      selectedSortValue={selectedSortValue}
      id={menuItem}
    >
      <CustomCarousel
        big={big}
        type={type}
        items={items ?? []}
        controlsOnTopRight={controlsOnTopRight}
      />
    </PageSection>
  );
};

export default GameCarousel;
